header {
  padding: 16px 0;
}

.container-header {
  text-align: center;
}

.header__logo {
  height: auto;
  display: flex;
  justify-content: center;
}

.header__paw {
  width: 24px;
  height: auto;
  margin-right: 5px;
}

.header__wordmark {
  width: 104px;
  height: auto;
}
