footer {
  background-color: #f8f8f8;
  padding: 27px 0;

  .legal-block {
    &-in {
      align-items: center;
      display: flex;
      font-size: 12px;
      justify-content: space-between;
      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    &-left {
      align-items: center;
      display: flex;
      padding-right: 20px;

      @media only screen and (max-width: 767px) {
        padding-bottom: 20px;
        padding-right: 0;
      }

      .macpaw-logo {
        display: flex;
        margin-right: 28px;
      }
    }

    ul.footer-menu {
      margin: 0 0 8px;
      padding: 0;

      li {
        display: inline-block;
        a {
          color: #333;
          text-decoration: none;
          transition: 0.3s;
          &:hover {
            color: #1d9fff;
            transition: 0.3s;
          }
        }
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }

    .company-info {
      color: rgba(51, 51, 51, 0.48);
      line-height: 18px;
    }

    &-right {
      color: #fff;
      display: flex;
      font-size: 11px;
      font-weight: 600;
      line-height: 13px;

      .dmca {
        background-color: rgba(0, 0, 0, 0.32);
        padding: 4px 10px;
      }

      .protected {
        background-color: #666;
        padding: 4px 10px;
      }
    }
  }
}
