body {
  font: 16px/1.75 'Montserrat', 'Helvetica Neue', sans-serif;
  margin: 0;
}

/* @Typography */
h1 {
  font-size: 32px;
  font-weight: bold;
  line-height: 48px;
  margin: 16px 0;
}

h2 {
  color: #333;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  margin: 0 auto;
  max-width: 760px;
}
