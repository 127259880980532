.main-app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
}

.main-app > main {
  flex-grow: 1;
  padding-bottom: 86px;
  text-align: center;
}
