.background {
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate(-50%);
  z-index: -1;
}

.main-container {
  img {
    display: block;
    margin: 0 auto;
  }

  p {
    color: #333;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    margin: 24px 0;
  }
}

/* TODO: Create button component fot this styles */
main a {
  border-radius: 10px;
  display: inline-block;
  font-size: 16px;
  height: 48px;
  padding: 0 35px;
  text-decoration: none;
}

main .blog {
  border: 2px solid #000;
  box-sizing: border-box;
  color: #000;
  font-weight: 600;
  line-height: 44px;
  transition: 0.3s;

  &:hover {
    border-color: rgba(51, 51, 51, 0.1);
    transition: 0.3s;
  }
}

main .main-button {
  background-color: #06c668;
  color: #fff;
  line-height: 48px;
  margin-top: 28px;
  transition: 0.3s;

  &:hover {
    background-color: #0ad482;
    transition: 0.3s;
  }
}

.blog-info {
  margin-top: 96px;
}
